@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');

:root {
  --active-color: #ffec00;
  --secondary-color: #FF8F8F; /*ffec00*/
  --nav-font: 'Kode Mono', monospace;
}

.navbar-top {
  font-family: var(--nav-font);
  font-size: 20px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  height: 85px;
  background-color: #000000a9;
  box-shadow: 0 0 10px #000;
}

  .nav-interwrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
  }

  .navlist {
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
    gap: 6px;

    margin: 0;
    padding: 5px 7px 5px 7px;
    border: none;
    outline: none;
  }

  .logo-title-wrap {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 4px;
    margin-left: 25px;
    justify-content: space-evenly;
    color: #fff
  }

  .logo {
    margin: auto;
    width: 50px;
    /* height: 40px; */
  }
  
  .navlist-item {
    /* margin: 0 20px; */
    color: #fff;

    margin: auto;
    padding: 5px;
    border: none;
    outline: none;
    display: inline-block;
    padding: 8px 13px;
    line-height: 32px;
    text-decoration: none;
    text-align: left;

    -webkit-transition: color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out;
    -o-transition: color .2s ease-in-out;
    -ms-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
    cursor: pointer;
  }

  .navlist-spacer {
    color: #fff;
    margin: auto;
    padding: 5px;
    border: none;
    outline: none;
    display: inline-block;
    padding: 8px 13px;
    line-height: 32px;
    text-decoration: none;
    text-align: left;
  }

  /* nav li a {
    display: inline-block;
    padding: 8px 13px;
    line-height: 32px;
    text-decoration: none;
    text-align: left;

    -webkit-transition: color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out;
    -o-transition: color .2s ease-in-out;
    -ms-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;

    color: #fff;
  } */

  .navlist-item, .navlist-item:visited {
    text-decoration: none;
    outline: 0;
    color: #fff;
 
    -webkit-transition: color .3s ease-in-out;
    -moz-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out;
 }
 
 .navlist-item:hover, .navlist-item:focus { color: #fff; }
  
  .navlist-item.active {
    /* background-color: transparent !important; */
   color: var(--active-color); 
   font-weight: 600;
   text-shadow: 0 0 6px var(--active-color);
  }

  .small-navlist-item.active {
    /* background-color: transparent !important; */
   color: var(--active-color); 
   font-weight: 600;
   text-shadow: 0 0 6px var(--active-color);
  }

  .navlist-item:hover {
    transition: 0.25s ease-in-out;
    color: var(--active-color); 
  }


  .navlist-item.active::after { 
    padding-top: 0;
    border-bottom:3px solid var(--secondary-color);
    box-shadow: 0px 0px 4px var(--secondary-color);
    display: flex;
    margin: 0 0 0 auto;
    content: "";
    width: auto;
    translate: -1.5px;
    /* transition: 1s ease; */
  }

  .small-navlist-item {
    color: #fff;

    margin: auto;
    padding: 5px;
    border: none;
    outline: none;
    display: inline-block;
    padding: 8px 13px;
    line-height: 32px;
    text-decoration: none;
    text-align: left;

    -webkit-transition: color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out;
    -o-transition: color .2s ease-in-out;
    -ms-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
    cursor: pointer;

    margin: 0.75rem 0.5rem;
    cursor: pointer;
    color: white;
    font-size:1.6rem;
    text-align: right;
  }

  .small-navlist-item.active .small {
    margin-left: auto;
    width: min-content;
  }

  .small-navlist-item.active .small::after { 
    padding-top: 0;
    border-bottom:3px solid var(--secondary-color);
    box-shadow: 0px 0px 4px var(--secondary-color);
    display: flex;
    margin: 0 0 0 auto;
    content: "";
    width: auto;
    translate: -1.5px;
    /* transition: 1s ease; */
  }

/* .navbar-mainsize {

} */


.app__navbar-smallscreen {
  display: none;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* background: rgba(0, 0, 0, 0.8); */
  margin: auto 20px auto auto;
  color: var(--active-color);
  font-size: 40px;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.85);
    transition: 0.5s ease-in-out;
    flex-direction: column;
    /* z-index: 5; */
}

.app__navbar-smallscreen_overlay .overlay__close {
    color: #fff;
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40%;
}
/* 
.app__navbar-smallscreen_links li {
    margin: 1rem;
    cursor: pointer;
    color: white;
    font-size: 2rem;
    text-align: right;
} */

.app__navbar-smallscreen_links li:hover {
    color: var(--active-color);
}

.overlay__close {
  color: #fff;
}

  @media only screen and (max-width: 675px)  {
    .navbar-mainsize {
      display: none;
    }

    .app__navbar-smallscreen {
      /* position: fixed; */
      display: flex;
      right: 0;
      padding: 10px 0;
      /* margin: 20px */
     }

     .logo-title-wrap h1 {
      font-size: 1.4rem;
     }

    
  }