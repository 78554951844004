@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');

.form-wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;

    /* font-family: 'Satoshi', sans-serif; */
    font-family: "Open Sans", sans-serif;

    background: url(../../assets/softadinqra.webp);
    background-position: center;
    background-size: 500px;
    background-repeat: no-repeat;
    /* background: url("./bg.webp"); */
    /* background-repeat: no-repeat;
    background-size: cover; */
    /* overflow: auto; */
}

.form-wrap::-webkit-scrollbar {
    /* display: none; */
}

.form-content {
    box-sizing: border-box;
    width: 100%;
    height: auto;

    color: whitesmoke; 
    display: flex;
    flex-direction: column; 
    justify-content: start;
    gap: 10px;
    /* align-items: center; */
    min-height: 100vh; 
    line-height: 1.6; 
    overflow: auto;
    padding: 85px 0 100px 0;
}

.form-content::-webkit-scrollbar {
    display: none;
}

.form-header {
    /* font-family: 'Satoshi', monospace; */
    font-size: 1.5rem;
    font-family: "Kode Mono", monospace;
    color: whitesmoke;
    text-shadow: 0 0 2px;
    /* text-align: center; */
    margin: 0 auto;
    margin-top: 14px;
    width: 50%;
    max-width: 550px;
}

.header-text {
    font-weight: bold;
    font-family: "Kode Mono", monospace;
    text-align: center;
    white-space: pre-wrap;
    display: flex;
    flex-direction: row;
}

.form-section {
    box-sizing: border-box;
    white-space: normal;
    background-color: #1b1b1b9a; 
    padding: 25px;
    border-radius: 15px; 
    box-shadow: 0px 0px 6px 0 var(--accent-purple);
    width: 50%;
    max-width: 550px;
    line-height: 1.6; 
    margin: auto;

    border-radius: 8px;
    padding: 30px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.section-header {
    color: white;
}

.group-fields {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.input-field {
    position: relative;
    border-bottom: 2px solid #ccc;
    margin: 15px 0;
    width: 100%;
}

.input-field label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: #fff;
    font-size: 16px;
    pointer-events: none;
    transition: 0.15s ease;
}

.input-field input {
    width: 100%;
    height: 40px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    color: #fff;
}

.input-field input:focus~label,
.input-field input:valid~label {
    font-size: 0.8rem;
    top: 10px;
    transform: translateY(-120%);
}

.horizontal-input {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    text-overflow: clip;
    gap: 20px;
    justify-content: space-between;
}

.flex-end {
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.vertical-input {
    display: flex;
    flex-direction: column;
    gap: 6px;
    /* vertical-align: middle; */
}

.horizontal-input label {
    float: left;
    word-wrap:break-word;
    /* width: 30%; */
    margin: auto 0;
    color: white;
}

.vertical-input label {
    float: left;
    word-wrap:break-word;
    margin: auto 0;
    color: white;
}

.question-section {
    background-color: #EFEFEF; 
    padding: 15px 25px;
    margin-top: 40px;
    border-radius: 10px; 
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.295);
    line-height: 1.6; 
}

.justify-text-right {
    text-align: right;
    margin: auto 0;
    width: 15%;
}

.justify-text-left {
    text-align: left;
}

.horizontal-input select, input {
    padding: 2px 4px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    /* line-height: 1.6; */
    justify-content: right;
    text-align: right;
    background: var(--accent-purple);
    color: white;
    width: min-content;
}

.areas-of-interest-field {
    margin: 0 auto;
    padding: 2px 4px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background: var(--accent-purple);
    color: white;
    width: min-content;
    resize: none;
    width: 100%;
}

.end-form-gap {
    /* margin-bottom: 140px; */
}


/* input[type="checkbox"], input[type="radio"] {
    width: auto;
    margin-right: 10px;
    cursor: pointer;
    } */
    
    .checkbox-group, .radio-group {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
    }
    
    .checkbox-column, .radio-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
    }
    
    .submit-button {
        padding: 12px 24px;
        background-color: #fff; 
        color: #ff0000;
        font-weight: 600;
        border: none;
        border-radius: 10px; 
        cursor: pointer;
        font-size: 16px; 
        font-family: 'Helvetica Neue', Arial, sans-serif;
        /* margin-top: 25px; */
        width: 100%; 
        line-height: 1.6; 
        transition: 0.2s ease-in-out;
    }
    
    .submit-button:hover {
        background-color: var(--sharp-red);
        color: rgb(248, 248, 248);
    }
    
    .submit-button.pop {
        transform: scale(1.1);
        background-color: #f50404; 
        color: #ffffff; 
        border: 1px solid #ffffff;
    }
    
    .checkbox-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: white;
        
    }

    .terms-wrap {
        display: flex;
        flex-direction: row;
        /* flex-wrap: wrap; */
        text-overflow: clip;
        gap: 20px;
        justify-content: space-between;
    }

    .extendbar {
        border: 1px solid var(--cool-grey);
        width: 100%;
        margin: auto 0 0 0;
    }
                
    @media screen and (max-width: 900px) {
        .form-section {
            width: 80%;
            max-width: 450px;
        }

        .form-header {
            width: 80%;
            max-width: 450px;
        }
    }
    
    @media only screen and (max-width: 675px)  {
        .form-header {
            font-size: 1.2rem;
        }
    }

    @media only screen and (max-width: 550px)  {
        .form-section {
            width: 92%;
        }

        .form-header {
            width: 92%;
            font-size: 1.1rem;
        }

        .horizontal-input {
            flex-wrap: wrap;
            gap: 0;
        }

        .form-wrap {
            background-size: 250px;
        }
    }
