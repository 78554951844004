@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');

.footer-wrap {
    box-sizing:border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* position: absolute; */
    bottom: 0;
    left: 0;
    padding: 4px 4px;
    background: #000;
    box-shadow: 0 0 8px #000;
}

.footer-container {
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.footer-text {
    font-family: "Kode Mono", monospace;
    color: var(--sharp-red);
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.white-emphasis {
    color: #fff;
    text-align: right;
}

.footer-logo {
    margin: auto 0;
    width: 14px;
    height: 13px;

    /* height: 40px; */
  }

  .footer-hide {
    visibility: hidden;
  }

  .web-design-title {
    /* width: auto; */
    font-family: "monospace", monospace;
    margin: auto 0 auto 0;
    color: #2e2e2e;
    font-size: 10px;
    align-self: flex-end;
    cursor: pointer;
    text-decoration: none;
  }

  .web-design-title:hover {
    color: var(--neon-blue);
    font-weight: 700;
    text-shadow: 0 0 3px;
  }

  @media only screen and (max-width: 500px)  {
    .footer-hide {
        display: none;
    }
}