:root {
  
  
  
  --main-color-black: #272838;
  --accent-purple: #5D536B;
  --cool-grey: #989FCE;
  --bright-green: #B3EFB2;
  --sharp-red: #FF8F8F;
  --accent-gold: #fff568;
  --neon-blue: #00FFFF;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll;
  width: 100%;
  height: 100vh;
/* 
  background-color: var(--main-color-black);
  background-image: url(./assets/crispbg.webp);
  background-size: cover; */
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide-form {
  display: none;
  visibility: hidden;
}
