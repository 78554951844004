@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

.disclosures-wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    background-blend-mode: luminosity;
    background-position: -12px -12px;
    background: url(../../assets/softadinqra.webp);
    background-position: center;
    background-size: 400px;
    background-repeat: no-repeat;
    gap: 30px;
    padding-top: 85px;
    box-sizing: border-box;
}

.disclanding-wrap {
    box-sizing: border-box;
    width: 60%;
    height:90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    gap: 12px;
}

.disclosures-landing {
    margin: 15px 0 0 0;
    /* background-color: rgba(0, 0, 0, 0.349); */
    /* border: 1px solid #666666; */
    color: #fff;
    font-family: "Kode Mono", monospace;
    font-size: 2.25rem;
    /* margin: 0 auto; */
    width: 100%;
    display: flex;
    flex-direction: column;
    display: flex;
    white-space: nowrap;  
}

.extendbar {
    border: 1px solid var(--cool-grey);
    width: 100%;
    margin: auto 0 0 0;
}

.comingsoon {
    font-family: "Kode Mono", monospace;
    color: var(--sharp-red);
    text-align: right;
    font-size: 1rem;
    font-style: italic;
    text-shadow: 0 0 5px;
    font-weight: 700;
    line-height: 1;
}

.pdf-viewer-wrap {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.pdf-viewer {
    box-sizing: border-box;
    border: #000 inset 2px;
    width: 100%;
    height: 100%;
}

.disc-text {
    font-weight: bold;
    font-size: 1.5rem;
    font-family: "Kode Mono", monospace;
    color: whitesmoke;
    text-shadow: 0 0 2px;
    text-align: center;
    white-space: pre-wrap;
    display: flex;
    flex-direction: row;
}

.sign-btn {
    font-size: 1rem;
    color: #fff;
    font-family: "Kode Mono", monospace;
    font-weight: 700;
    background-color: #0000007e;
    border: 1px solid #fff;
    margin: 0 auto;
    padding: 10px;
    max-width: 50%;
    transition: 0.25s ease-in-out;
    cursor: pointer;
    margin-bottom: 10px;
}

.sign-btn:hover {
    background: #ffffff7e;
    border: 1px solid var(--neon-blue);
    box-shadow: 0px 0px 2px var(--neon-blue);
    color: #000;
}

@media only screen and (max-width: 900px) {
    .disclanding-wrap {
        width: 80%;
    }
}   

@media only screen and (max-width: 675px) {
    .disclanding-wrap {
        width: 90%;
    }
}

@media only screen and (max-width: 475px)  {
    .disclosures-landing {
        font-size: 1.75rem;
    }
    .disclanding-wrap {
        width: 95%;
    }
}
