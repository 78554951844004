@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');

.home-wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-blend-mode: luminosity;
    background-position: -12px -12px;
    /* background: url(../../assets/logo.svg); */
    /* filter: grayscale(1); */
    background-position: center;
    background-size: 500px;
    background-repeat: no-repeat;
    gap: 30px;

}

.landing-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    gap: 10px;
}

.home-landing {
    /* padding: 15px; */
    /* background-color: rgba(0, 0, 0, 0.349); */
    /* border: 1px solid #666666; */
    color: var(--cool-grey);
    font-family: "Kode Mono", monospace;
    font-size: 3.5rem;
    text-shadow: 0px 0px 5px;
    /* margin: 0 auto; */
    width: 100%;
    display: flex;
    flex-direction: column;
    line-height: 1;
    /* backdrop-filter: ; */
    filter: none;

    white-space: nowrap;  
}

.home-logo {
    width: 30px;
    margin: 0 0 0 auto;
}

.welcome-text {
    color: var(--cool-grey);
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    white-space: pre-wrap;
}

.adinqra-realty-wrap {
    display: flex;
    flex-direction: column;
}

.adinqra-emphasis {
    color: #fff;
    /* line-height: 1; */
}

.adinsubtext {
    color: var(--sharp-red);
    text-align: right;
    font-size: 1rem;
    font-style: italic;
    /* line-height: 0; */
}

.get-started-wrap {
    margin: 0 auto;
    font-family:  monospace;
    color: var(--accent-gold);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.please-fill {
    font-size: 1.2rem;
    text-align: center;
    /* border-top: 2px dashed #fff; */
}

.get-started-wrap b {
    font-family: "Kode Mono", monospace;
    text-shadow: 0 0 4px;
    /* font-style: italic;  */
}

.get-started-btn {
    font-size: 1rem;
    color: #fff;
    font-family: "Kode Mono", monospace;
    font-weight: 700;
    background-color: #0000007e;
    border: 1px solid var(--sharp-red);
    margin: 0 auto;
    padding: 10px;
    max-width: 50%;
    transition: 0.25s ease-in-out;
    cursor: pointer;
    border-radius: 0 10px 0 10px;
}

.get-started-btn:hover {
    background: #ffffff7e;
    border: 1px solid var(--neon-blue);
    box-shadow: 0px 0px 2px var(--neon-blue);
    color: #000;
}

@media only screen and (max-width: 475px)  {
    .home-wrap {
        background-size: 250px;
    }

    .home-landing {
        font-size: 1.85rem;
    }

    .please-fill {
        font-size: 0.8rem;
    }

    .adinsubtext {
        font-size: 0.8rem;
    }

    .get-started-btn {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 675px) and (min-width: 475px)  {
    .home-wrap {
        background-size: 400px;
    }

    .home-landing {
        font-size: 2.5rem;
    }

    .please-fill {
        font-size: 1rem;
    }
}